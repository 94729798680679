// react
import React from 'react';

// third-party
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { registerUser, login } from '../../store/directusSdk';
import { cartUpdatePrice } from '../../store/cart';
import { currentUserChange } from '../../store/currentUser';
import { CartItemsId } from '../shared/utilities';

function AccountFormRegister(props) {
    const { currentUserChange, countries, cart, RegisterCheckout } = props;
    const { type } = props;
    const cartItems = CartItemsId(cart);
    const valuesInitial = {
        first_name: '',
        last_name: '',
        ruc: '',
        phone: '',
        company: '',
        countryid: '',
        email: '',
        status: 'active',
        role:'6a3f04fe-51e0-408f-b9db-0b757d8b6a8b',
        password: '',
        repassword: '',
    };
    return (
        <React.Fragment>
            {!RegisterCheckout && (
                <>
                    <div className="card-header">
                        <h5>{`${type} Cuenta`}</h5>
                    </div>
                    <div className="card-divider" />
                </>
            )}
            <br />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-12 col-xl-12">
                        <Formik
                            initialValues={valuesInitial}
                            validationSchema={Yup.object({
                                first_name: Yup.string()
                                    .min(3, 'Minimo 3 caracteres.')
                                    .required('Campo requerido.'),
                                last_name: Yup.string()
                                    .min(3, 'Minimo 3 caracteres.')
                                    .required('Campo requerido.'),
                                ruc: Yup.string(),
                                phone: Yup.string()
                                    .required('Campo requerido.'),
                                company: Yup.string(),
                                countryid: Yup.string()
                                    .required('Campo requerido.'),
                                password: Yup.string()
                                    .min(6, 'Minimo 6 caracteres.')
                                    .required('Campo requerido.'),
                                repassword: Yup.string()
                                    .required('Campo requerido.')
                                    .oneOf([Yup.ref('password')], 'Contraseñas no coinciden.'),
                                email: Yup.string()
                                    .email('Correo electrónico invalido.')
                                    .required('Campo requerido.'),
                            })}
                            onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm, setFieldValue }) => {
                                const user = Object.assign({}, values);
                                delete user.repassword;
                                const account = registerUser(user);
                                account.then(() => {
                                    toast.success('Registro realizado con exito!.');
                                    login(user.email, user.password, cartItems).then((res) => {
                                        currentUserChange(res.user);
                                        cartUpdatePrice(res.productos, 'login');
                                        resetForm({});
                                        setStatus({ success: true });
                                    });
                                });
                                account.catch((e) => {
                                    setFieldValue('password', '', false);
                                    setFieldValue('repassword', '', false);
                                    if (e.code === '204') {
                                        setErrors({ email: 'Correo Electrónico en uso.' });
                                    }
                                    setStatus({ success: false });
                                    setSubmitting(false);
                                });
                            }}
                        >
                            {({ errors, touched, isSubmitting }) => (
                                <Form autoComplete="off">
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="first_name">Nombres</label>
                                            <Field
                                                name="first_name"
                                                id="first_name"
                                                className={`form-control ${errors.first_name && touched.first_name && 'is-invalid'}`}
                                                placeholder="Nombres..."
                                            />
                                            <ErrorMessage name="first_name" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="last_name">Apellidos</label>
                                            <Field
                                                name="last_name"
                                                className={`form-control ${errors.last_name && touched.last_name && 'is-invalid'}`}
                                                id="last_name"
                                                placeholder="Apellidos..."
                                            />
                                            <ErrorMessage name="last_name" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="ruc">RUC</label>
                                            <Field
                                                name="ruc"
                                                id="ruc"
                                                className={`form-control ${errors.ruc && touched.ruc && 'is-invalid'}`}
                                                placeholder="Ingrese numero de RUC..."
                                            />
                                            <ErrorMessage name="ruc" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="company">Compañia</label>
                                            <Field
                                                name="company"
                                                className={`form-control ${errors.company && touched.company && 'is-invalid'}`}
                                                id="company"
                                                placeholder="Nombre de la compañia..."
                                            />
                                            <ErrorMessage name="company" component="div" className="invalid-feedback" />
                                        </div>

                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="phone">Telefono</label>
                                            <Field
                                                name="phone"
                                                className={`form-control ${errors.phone && touched.phone && 'is-invalid'}`}
                                                id="phone"
                                                placeholder="Numero telefonico..."
                                            />
                                            <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="countryid">Pais</label>
                                            <Field
                                                as="select"
                                                name="countryid"
                                                id="countryid"
                                                className={`form-control form-control-select2 ${errors.countryid && touched.countryid && 'is-invalid'}`}
                                            >
                                                <option value="">Seleccione un País...</option>
                                                {countries.map((e) => <option key={e.id} value={e.id}>{e.countryname}</option>)}
                                            </Field>
                                            <ErrorMessage name="countryid" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="email">Correo Electronico</label>
                                            <Field
                                                name="email"
                                                id="email"
                                                className={`form-control ${errors.email && touched.email && 'is-invalid'}`}
                                                placeholder="ingrese Correo Electronico..."
                                            />
                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="password">Contraseña</label>
                                            <Field
                                                type="password"
                                                name="password"
                                                id="password"
                                                className={`form-control ${errors.password && touched.password && 'is-invalid'}`}
                                                placeholder="ingrese contraseña..."
                                            />
                                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="repassword">Repita Contraseña</label>
                                            <Field
                                                type="password"
                                                name="repassword"
                                                className={`form-control ${errors.repassword && touched.repassword && 'is-invalid'}`}
                                                id="repassword"
                                                placeholder="Repita Contraseña..."
                                            />
                                            <ErrorMessage name="repassword" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group mt-3 mb-0">
                                        <button className="btn btn-primary" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Guardando...' : 'Guardar'}</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    countries: state.pagesContent.countries,
    currentUser: state.currentUser,
    cart: state.cart,
});

const mapDispatchToProps = {
    currentUserChange,
    cartUpdatePrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountFormRegister);