// react
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';

// application
import PageHeader from '../shared/PageHeader';
import Pagination from '../shared/Pagination';
import ProjectCard from '../shared/ProjectCard';

export default class PageProjectsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
        };
    }

    handleSlideChange = (newIndex) => {
        this.setState({ activeIndex: newIndex });
    };

    render() {
        const { projects, layout, company } = this.props;
        const { activeIndex } = this.state;

        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Proyectos', url: '' },
        ];

        const projectSlides = projects.map((project, index) => (
            <CarouselItem key={project.id} active={index === activeIndex}>
                <ProjectCard project={project} layout={layout} />
            </CarouselItem>
        ));

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Proyectos — ${company && company[0].name}`}</title>
                </Helmet>

                <PageHeader header="Proyectos" breadcrumb={breadcrumb} />

                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="block">
                                <Carousel
                                    activeIndex={activeIndex}
                                    next={() => this.handleSlideChange((activeIndex + 1) % projects.length)}
                                    previous={() => this.handleSlideChange((activeIndex - 1 + projects.length) % projects.length)}
                                    ride="carousel"
                                    interval={5000} // Set your desired interval
                                >
                                    {projectSlides}
                                    <CarouselControl direction="prev" onClickHandler={() => this.handleSlideChange((activeIndex - 1 + projects.length) % projects.length)} />
                                    <CarouselControl direction="next" onClickHandler={() => this.handleSlideChange((activeIndex + 1) % projects.length)} />
                                    <CarouselIndicators items={projects} activeIndex={activeIndex} onClickHandler={this.handleSlideChange} />
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
