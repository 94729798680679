// react
import React from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';

export default function Footer(props) {
    const { company } = props;
    const informationLinks = [
        { title: 'Nosotros', url: '/site/about-us' },
        { title: 'Contáctanos', url: '/site/contact-us' },
        { title: 'Proyectos', url: '/proyectos' },
        { title: 'Afiliate', url: '/account/login' },
        { title: 'Ayuda', url: '/ayuda' },
        { title: 'Garantia', url: '/garantia' },

    ];

    const accountLinks = [
        { title: 'Localización', url: '/site/contact-us' },
        { title: 'Devoluciones', url: '/devoluciones' },
        { title: 'Polícticas de Privacidad', url: '/site/privacy-policies' },
        { title: 'Términos y Condiciones', url: '/site/terms' },
        { title: 'Condiciones de Venta', url: '/condiciones-de-venta' },
        { title: 'Preguntas Frecuentes', url: '/preguntas-frecuentes' },

    ];

    return (
        <>
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                        <img src="https://api-ecommerce.icautomatizados.com/assets/c5a19fcc-5c51-48cc-96c4-b06fd65db672" alt="ICAutomatizados" height="80px"/>
                        <FooterNewsletter />

                        </div>

                        <div className="col-12 col-md-12 col-lg-4">
                            {/* <FooterLinks title="Información" items={informationLinks} /> */}
                            <FooterLinks title="Información" items={informationLinks} />

                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                        <FooterLinks title="Mi Cuenta" items={accountLinks} />

                        {/* <FooterContacts company={company} /> */}
                        </div>
                    </div>
                </div>

            </div>
        </div>
                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        {`Ingeniería y Controles Automatizados S.A. Todos los derechos reservados ® 2014 - ${new Date().getFullYear()}.`}
                    </div>
                  
                </div>
    </>);
}
