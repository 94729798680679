/* eslint-disable */
import { calcSubtotal, calcITBMS, calcTotal } from "./cart/cartReducer";
import { Directus } from "@directus/sdk";
import { AddressesChange } from "./currentUser";

const directus = new Directus(process.env.REACT_APP_API_URL);
// export async function getProductsDiscounts() {
//     const data = await directus.items("products_configuration").readByQuery({
//         fields: ["sale_discount"],
//         status: "published",
//     })
//         .then((res) => res.data[0]);
//         const {sale_discount} = data
//         let discountPercentage;

//         if (sale_discount === "1") {
//             discountPercentage = "10%";
//         } else if (sale_discount === "2") {
//             discountPercentage = "15%";
//         } else if (sale_discount === "3") {
//             discountPercentage = "30%";
//         }
//         return discountPercentage
//         }
export async function recalculatePrice(products) {
    const data = await directus.items("products").readByQuery({
        fields: ["id", "price"],
        filter: {
            id: { _in: products },
        },
        status: "published",
    })
        .then((res) => res.data);
    return data;
}



export async function get_carousel() {
    const data = await directus.items("Carousel").readByQuery({
        fields: ["id", "imagen_title","code", "title","body","link_button","image","image_mobile",  "product_image", "background", "manual_slider","color_title","color_body"]
    })
        .then((res) => res.data);
    return data;
}
export async function refresh() {
    const data = await directus.auth.refreshIfExpired()
    console.log(data)
    return data
}
export async function login(email, password, cartProducts) {
    try {
        const data = await directus.auth.login({ email, password }).then(async (res) => {
           sessionStorage.setItem('token', JSON.stringify(res.access_token));
            const user = await directus.users.me.read(res.access_token,{ limit: -1})
        const addresses = await directus.items("customeraddress").readByQuery({
            fields: ["id", "address", "customer", "defaultaddress", "responsable", "email", "phone1", "phone2", "owner", "countryid.countryname", "countryid.id", "countryid.countrycode", "deliverycityid.id", "deliverycityid.cityname", "deliverycityid.deliveryprices.delivery", "deliverycityid.deliveryprices.freedelivery", "deliverycityid.deliveryprices.topprice", "deliverycityid.deliveryprices.price"],
            filter: { owner: { _eq: user.id } },
            status: "published",
            limit: -1
        });

        user.addresses = addresses.data || [];

        if (cartProducts.length > 0) {
            const productos = await recalculatePrice(cartProducts);
            return { user, productos };
        }

        return { user };
    } )
    return data
} catch (error) {
    if (error.message === "Invalid user credentials.") {
        console.error('Invalid user credentials:', error);
    } else {
        console.error('Error during login:', error);
    }
    return null; 
}}


export async function logout(cartProducts) {
    localStorage.clear()
    try {
        const res = await directus.auth.logout();

        if (cartProducts.length > 0) {
            const productos = await recalculatePrice(cartProducts);
            console.log(productos)
            return { res, productos };
        }

        return { res };
    } catch (error) {
        return { error };
    }
}

export async function ChangedPassword(id, newPass) {
    const data = await directus.users.me.update({ password: newPass })
    await directus.auth.refresh();
    return data;
}

export async function registerUser(newUser) {
    const data = await directus.items("directus_users").createOne(newUser);
    return data;
}
export async function registerEmail(newEmailSuscription) {
    const data = await directus.items("email_subscription").createOne(newEmailSuscription);
    return data;
}
export async function getProductsBySubCategory(id) {
    const datas = await directus.items("products").readByQuery({
        fields: ["id", "code", "code_manufacture", "name", "brand", "model", "description", "price", "amp", "hp", "kw", "voltaje", "rpm", "frame", "polos", "fp", "efficiency", "fs", "type", "connection", "phases", "temperature", "output", "input", "pressure", "flow", "color", "seal", "diameter", "thread", "application", "protection", "mounting", "communication", "pulses", "curve", "capacity", "unit", "rank", "long", "size", "caliber", "pines", "hours", "minutes", "seconds", "function", "memory", "contacts", "coil", "material", "available", "OnOrder", "extras", "pdfs", "video", "especificaciones", "accesories", "images", "days", "alternatives", "OnHandByWhs", "fprice", "width", "height", "volumen", "weight","price_without_sale_price", "feature",
        "sale_price","external_inventories.id","external_inventories.qty"],
        filter: {
            status: { _eq: 'published' },
            subcategoryid: { _eq: id },
        },
        sort: "-available,-OnOrder",
        limit: -1,
    });
    const data = datas.data.map((pr) => {
        let tqty = 0;
        pr.external_inventories && pr.external_inventories.map((ele) => {
            tqty += parseInt(ele.qty)
        })
        pr.externalQty = tqty
        return ({ ...pr })
    })
    return ({ data: data });
}
export async function getSaleProducts(id) {
    const datas = await directus.items("products").readByQuery({
        fields: ["id", "code", "code_manufacture", "name", "brand", "model", "description", "price",  "images", "feature","price_without_sale_price",
        "sale_price","external_inventories.id","external_inventories.qty"],
        filter: {
            status: { _eq: 'published' },
            feature: {_nnull: true },
        },
    });
    const data = datas.data.map((pr) => {
        let tqty = 0;
        pr.external_inventories && pr.external_inventories.map((ele) => {
            tqty += parseInt(ele.qty)
        })
        pr.externalQty = tqty
        return ({ ...pr })
    })
    const hotProducts = data.filter(product => product.feature.includes(id));

    return ({ data: hotProducts });
}
export async function getProduct(id) {
    const data = await directus.items("products").readOne(id, {
        fields: ["id", "code", "code_manufacture", "name", "brand", "model", "description", "price", "amp", "hp", "kw", "voltaje", "rpm", "frame", "polos", "fp", "efficiency", "fs", "type", "connection", "phases", "temperature", "output", "input", "pressure", "flow", "color", "seal", "diameter", "thread", "application", "protection", "mounting", "communication", "pulses", "curve", "capacity", "unit", "rank", "long", "size", "caliber", "pines", "hours", "minutes", "seconds", "function", "memory", "contacts", "coil", "material", "available", "OnOrder", "extras", "pdfs", "video", "especificaciones", "accesories", "images", "days", "alternatives", "OnHandByWhs", "fprice", "width", "height", "volumen", "weight", "comments", "feature" +
            "subcategoryid", "subcategoryid.id", "subcategoryid.descripcion", "subcategoryid.categoriaid.id","subcategoryid.categoriaid.descripcion", "external_inventories.id","feature", "external_inventories.id","external_inventories.qty","external_inventories.inventory_id.id","external_inventories.inventory_id.pseudonym","external_inventories.inventory_id.time_delivery" ,"price_without_sale_price",
            "sale_price",],
        limit: -1,

    });
    console.log(data)
    const existence = []
    let externalQty = 0
    data &&
        data &&
        data.external_inventories &&
        data.external_inventories.forEach((element) => {
            externalQty += parseInt(element.qty);
            existence.push({
                qty: element.qty,
                inv: element.inventory_id.pseudonym,
                time: element.inventory_id.time_delivery,
            });
        });
    data.existence = existence;
    data.externalQty = externalQty;
    return data;
}

export async function getSuggestedProducts(string) {
    const data = await directus.items("products").readByQuery({
        fields:[  "*","OnOrder","status","subcategoryid.id","subcategoryid.descripcion","subcategoryid.categoriaid.id","subcategoryid.categoriaid.descripcion"],
        filter: {
            status: { _eq: 'published' },
            "_or": [
                {
                    "_or": [
                        {
                            name: { _starts_with: string }
                        }
                    ]
                },
                {
                    "_or": [
                        {
                            code: { _starts_with: string }
                        }
                    ]
                },
                {
                    "_or": [
                        {
                            code_manufacture: { _starts_with: string }
                        },
                    ]
                }
            ]

        },
        sort: "-available,-OnOrder",
        limit: -1
    });
    data.data = data.data.filter((p) => p.status === "published")
    return data;
}



export async function getSearchProducts(string) {
    const data = await directus.items("products").readByQuery({
         fields: ["*", "OnOrder", "status", "subcategoryid.id", "subcategoryid.descripcion", "subcategoryid.categoriaid.id", "subcategoryid.categoriaid.descripcion"],
        filter: {
            status: { _eq: "published" },
            "_or": [
                {
                    "_or": [
                        {
                            name: { _starts_with: string }
                        }
                    ]
                },
                {
                    "_or": [
                        {
                            code: { _starts_with: string }
                        }
                    ]
                },
                {
                    "_or": [
                        {
                            code_manufacture: { _starts_with: string }
                        },
                    ]
                }
            ]
        },
         sort: "-available,-OnOrder",
    });
    data.data = data.data.filter((p) => p.status === "published")
    return data;
}

export async function getAccessories(codes) {
    const datas = await directus.items("products").readByQuery({
        fields: ["*", "OnOrder", "status", "subcategoryid.id", "subcategoryid.descripcion", "subcategoryid.categoriaid.id", "subcategoryid.categoriaid.descripcion", "" +
            "external_inventories.id", "" + "external_inventories.qty"],
        filter: { status: { _eq: 'published' }, code: { _in: codes } },
        status: "published",
        sort: "-available,-OnOrder",
    });
    const data = datas.data.map((pr) => {
        let tqty = 0;
        pr.external_inventories && pr.external_inventories.map((ele) => {
            tqty += parseInt(ele.qty)
        })
        pr.externalQty = tqty
        return ({ ...pr })
    })
    return ({ data: data });
}

export async function getAlternatives(codes) {
    const datas = await directus.items("products").readByQuery({
        fields: ["*", "OnOrder", "status", "subcategoryid.id", "subcategoryid.descripcion", "subcategoryid.categoriaid.id", "subcategoryid.categoriaid.descripcion", "" +
            "external_inventories.id", "" + "external_inventories.qty"],
        filter: { status: { _eq: 'published' }, code: { _in: codes } },
        sort: "-available,-OnOrder",
    });
    const data = datas.data.map((pr) => {
        let tqty = 0;
        pr.external_inventories && pr.external_inventories.map((ele) => {
            tqty += parseInt(ele.qty)
        })
        pr.externalQty = tqty
        return ({ ...pr })
    })
    return ({ data: data });

}

export async function getCustomerAddress(userId) {
    const data = await directus.items("customeraddress").readByQuery({
        fields: ["id", "address", "customer", "defaultaddress", "responsable", "email", "phone1", "phone2", "owner", "countryid.countryname", "countryid.id", "countryid.countrycode", "deliverycityid.id", "deliverycityid.cityname", "deliverycityid.deliveryprices.delivery", "deliverycityid.deliveryprices.freedelivery", "deliverycityid.deliveryprices.topprice", "deliverycityid.deliveryprices.price"],
        filter: { owner: { _eq: userId } },
        status: "published",
    });

    return data;
}

export async function getDefaultAddress(userId) {
    const data = await directus.items("customeraddress").readByQuery({
        fields: ["id", "responsable", "address", "phone1", "phone2", "email", "defaultaddress", "countryid.countrycode", "countryid.countryname", "countryid.id", "deliverycityid.cityname", "deliverycityid.id"],
        status: "published",
        filter: {
            defaultaddress: { _eq: 1 },
            owner: { _eq: userId },
        },
    });
    return data;
}

export async function searchCustomerAddress(id) {
    const data = await directus.items("customeraddress").readOne(id);
    return data;
}
export async function addCustomerAddress(address, userId) {

    if (address.defaultaddress) {
        let data;
        await directus.items("customeraddress").readByQuery( {
                status: "published",
                filter: {
                    defaultaddress: { _eq: 1 },
                    owner: { _eq: userId },
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    data = res.data.map((e) => {
                        e.defaultaddress = 0;
                        return e;
                    });
                }
            });
        if (data) {
                await directus.items("customeraddress").updateOne(data[0].id, data[0] );
            }

        }
        const owner= userId.id
        const data = await directus.items("customeraddress").createOne({
            ...address,
            owner: owner // Assuming `userId` is the user's ID
        });
    
        return data;
    
    }



export async function removeCustomerAddress(id) {
    const data = await directus.items("customeraddress").deleteOne(id);
    return data;
}
export async function editCustomerAddress(body) {
    const data = await directus.items("customeraddress").updateOne(body[0].id, body[0]);
    return data;
}
export async function setDefaultAddress(data) {
    if(data.length>1){
    const dat = await directus.items("customeraddress").updateOne(data[0].id, data[0]);
    const dat2 = await directus.items("customeraddress").updateOne(data[1].id,data[1]);
    return dat, dat2;
    }

const result = await directus.items("customeraddress").updateOne(data[0].id, data[0]);

     return result
}


export async function UploadEvidence(data, onUploadProgress) {
    const result = await directus.files.createOne(data, onUploadProgress);
    return result;
}


export async function AddOrder(order) {
    if (order.attachment) {
        await directus.files.createOne(order.attachment).then((res) => {
            order.attachment = res.data;
        });
    }
    const ord = await directus.items("orders").createOne(order).then((res) => res);
    return ord;
}

export async function addTransactionLog(amount, orderid) {
    const data = await directus.items("transactionlog").createOne({ amount, orderid });
    return data;
}

export async function addMessage(message) {
    const data = await directus.items("contactform").createOne(message);
    return data;
}

export async function ResetPassword(email) {
    const data = await directus.auth.password.request(email);
    return data;
}

export async function resetedPassword(token, password) {
    const data = await directus.transport.post("/auth/password/reset", { token, password });
    return data;
}

export async function getOrders(cliente) {

    const data = await directus.items("orders").readByQuery({
        fields: ["id", "total", "created_on", "status"],
        filter: { owner: { _eq: cliente } },
        sort: "-id",
    });
    console.log(data)
    return data;
}

export async function getOrder(id) {
    const data = await directus.items("orders").readOne(id, {
        fields: ["id", "billingaddress", "shippingaddress", "total", "created_on", "status", "subtotal", "itbms", "orderlines.*"]
    });
    return data;
}

export async function updateOrder(id, body) {
    const data = await directus.items("orders").updateOne(id, body);
    return data;
}

export async function updateTransactionLog(id, formid) {
    const data = await directus.items("transactionlog").updateOne(id, { formid });
    return data;
}

export async function CreateQuotation(quotation) {
    const data = await directus.items("quotation").createOne(quotation)
    return data
}
export async function getQuotationById(id) {
    const data = await directus.items("quotation").readByQuery({
        fields: ["id", "pdf"],
        filter: { id: { _eq: id } },
    });
    return data;
}
export async function getQuotations(cliente) {
    const data = await directus.items("quotation").readByQuery({
        fields: ["id", "total", "created_on", "validity", "items", "cart"],
        filter: { owner: { _eq: cliente } },
        sort: "-id",
    });
    return data;
}

export async function getAvailablePrices(cart) {
    const ids = cart.items.map((e) => e.product.id);
    const data = await directus.items("products").readByQuery({
        fields: ["id", "price", "OnHandByWhs"],
        filter: { id: { _in: ids } },
    })
        .then((res) => {
            res.data.map((e) => {
                const product = cart.items.findIndex((pro) => pro.product.id === e.id);
                if (product !== -1) {
                    if (e.OnHandByWhs) {
                        const bodegas = e.OnHandByWhs.split(",");
                        const bod01 = bodegas[0].split(":");
                        const bod05 = bodegas[0].split(":");
                        cart.items[product].product.bod01 = parseInt(bod01[1]);
                        cart.items[product].product.bod05 = parseInt(bod05[1]);
                        cart.items[product].price = e.price;
                    } else {
                        cart.items[product].product.bod01 = 0;
                        cart.items[product].product.bod05 = 0;
                    }
                } else {
                    console.log("no se encontro producto: " + e.id);
                }
            });
            return cart;
        });
    return data;
}

export async function getAvailable(cart) {
    console.log(cart);
    const ids = cart.items.map((e) => e.product.id);
    const data = directus.items("products").readByQuery({
        fields: ["id", "OnHandByWhs"],
        filter: { id: { _in: ids } },
    })
        .then((res) => {
            res.data.map((e) => {
                const product = cart.items.findIndex((pro) => pro.product.id === e.id);
                if (product !== -1) {
                    if (e.OnHandByWhs) {
                        const bodegas = e.OnHandByWhs.split(",");
                        const bod01 = bodegas[0].split(":");
                        const bod05 = bodegas[1].split(":");
                        cart.items[product].product.bod01 = parseInt(bod01[1]);
                        cart.items[product].product.bod05 = parseInt(bod05[1]);
                        cart.items[product].product.OnHandByWhs = e.OnHandByWhs;
                    } else {
                        console.log("no se encontro producto: " + e.id + " en cart -> items");
                    }
                } else {
                    console.log("no se encontro producto: " + e.id);
                }
            });
            cart.subtotal = calcSubtotal(cart.items);
            cart.itbms = calcITBMS(cart.subtotal, cart.shipping);
            cart.total = calcTotal(cart.subtotal, cart.shipping);
            return cart;
        });
    return data;
}

export async function getDeliveryCity(id) {
    const data = await directus.items("deliverycities").readByQuery({
        fields: ["id", "cityname", "deliveryprices.id", "deliveryprices.delivery", "deliveryprices.freedelivery", "deliveryprices.topprice", "deliveryprices.price"],
        filter: {
            _eq: id
        }
    })
        .then((res) => res.data);
    return data;
}
export async function getContent() {
        const data = await directus.transport.get('/get_content_pages').then(data => data.raw);
        return data;

}

