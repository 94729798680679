/* eslint-disable linebreak-style */
// react
import React, { useState,useEffect } from 'react';
// third-party
import { Helmet } from 'react-helmet-async';

// blocks
import BlockBrands from '../blocks/BlockBrands';
import BlockCategories from '../blocks/BlockCategories';
import BlockFeatures from '../blocks/BlockFeatures';
import BlockSlideShow from '../blocks/BlockSlideShow';
import Whastapp from '../../svg/whatsapp.png';
import BlockProducts from '../blocks/BlockProducts';
import { getSaleProducts } from '../../store/directusSdk';
import BlockCarousel from '../blocks/BlockCarousel';


export default function HomePageOne(props) {
    const keyWords = 'SALE';
    const [feautedProducts, setFeautedProducts] = useState([]);
    const { categories, brands } = props;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getSaleProducts(keyWords);
                setFeautedProducts(res.data);
            } catch (error) {
                console.error('Error fetching carousel data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>Ingeniería y Controles Automatizados</title>
            </Helmet>

            {/* <BlockSlideShow /> */}
            <BlockCarousel />

            <BlockFeatures />
            <BlockCategories title="Categorias" layout="classic" categories={categories} />
            <BlockProducts
                title="Productos en Oferta"
                layout="large-first"
                featuredProduct={feautedProducts[0]}
                products={feautedProducts.slice(1, 9)}
            />

            <BlockBrands brands={brands} />
            <div className="whatsapp-float">
  <a href="https://api.whatsapp.com/send?phone=65409240&text=Hola%20desde%20la%20web%20quisiera%20más%20información" target="_blank">
  <img src={Whastapp} alt="WhatsApp" />  </a>
</div>


        </React.Fragment>
    );
}