// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import 'moment/locale/es';
// application
import PageHeader from '../shared/PageHeader';


function PageContent(props) {
    const { company, content } = props;
    console.log(company)
    if (content === undefined) {
        return null;
    }
    const modified = moment(content.modified_on, 'YYYY/MM/DD HH:mm:ss').locale('es').format('LL');

    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: content.title, url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${content.title} — ${company.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">{content.title}</h1>
                            <div className="document__subtitle">{`Este Acuerdo fue modificado por última vez el ${modified}`}</div>
                        </div>
                        <div
                            className="document__content typography"
                            dangerouslySetInnerHTML={{ __html: content.body }}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PageContent;
